import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: ()=> import('@/views/HomeView.vue')
//   },
//   {
//     path: '/introduce',
//     name: 'introduce',
//     component: ()=> import('@/views/Introduce.vue')
//   },
//   {
//     path: '/radio',
//     name: 'radio',
//     component: ()=> import('@/views/WatchRadio.vue')
//   },
//   {
//     path: '/analysis',
//     name: 'analysis',
//     component: ()=> import('@/views/ownDataAly.vue')
//   },
//   {
//     path: '/login',
//     name: 'login',
//     component: ()=> import('@/views/Login.vue')
//   },
//   {
//     path: '/my',
//     name: 'myInfo',
//     component: ()=> import('@/views/MyInfo.vue')
//   },
//   {
//     path: '/text',
//     name: 'text',
//     component: ()=> import('@/views/Text.vue')
//   }
// ]
const routes = [
  {
    path: '/',  // 程序启动默认路由
    component: () => import('@/components/Whole.vue'),
    meta: { title: '整体页面布局' },
    redirect: '/test1',  // 重定向到首页
    children: [
      {
        path: '/test1',
        component: () => import('@/views/text1/index.vue'),
        meta: { title: '一级菜单1' },
        redirect: '/test1/test1-1',  // 该配置是若点击选择一级菜单时，默认选中并跳转到该一级菜单下的第一个二级菜单
        children:[
          {
            path: 'test1-1',
            component: () => import('@/views/text1/text1-1.vue'),
            meta: { title: '二级菜单1-1' },
          }
        ]
      },
      {
        path: '/test2',
        component: () => import('@/views/text2/index.vue'),
        meta: { title: '一级菜单2' },
        redirect: '/test2/test2-1',     // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
        children:[
          {
            path: 'test2-1',
            component: () => import('@/views/text2/text2-1.vue'),
            meta: { title: '二级菜单2-1' },
          }
        ]
      },
      {
        path: '/test3',
        component: () => import('@/views/text3/index.vue'),
        meta: { title: '一级菜单3' }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/Login.vue')
  },
  {
    path: '/my',
    name: 'myInfo',
    component: ()=> import('@/views/MyInfo.vue')
  },
  {
    path: '/member',
    name: 'member',
    component: ()=> import('@/views/Member.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: ()=> import('@/views/order.vue')
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: ()=> import('@/views/Introduce.vue')
  },
  {
    path: '/radio',
    name: 'radio',
    component: ()=> import('@/views/WatchRadio.vue')
  },
  {
    path: '/text',
    name: 'text',
    component: ()=> import('@/views/Text.vue')
  }
]
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {
//   const isLoggedIn = JSON.parse(window.localStorage.getItem("loginInfo"));
//   if (to.path === '/login') {
//       if (isLoggedIn) {
//           // 如果已经登录，重定向到导航页面（可根据实际需求调整）
//           next('/');
//       } else {
//           next();
//       }
//   } else if (to.path === '/introduce' || to.path === '/radio' || to.path === '/analysis' || to.path === '/my' || to.path === '/text') {
//       if (!isLoggedIn) {
//           // 如果未登录且访问需要登录的页面，重定向到登录页面
//           if (from.path!== '/login') {
//               next('/login');
//           } else {
//               // 如果当前页面已经是登录页面，不进行重定向
//               next(false);
//           }
//       } else {
//           next();
//       }
//   } else {
//       next();
//   }
// });


export default router
